<!-- 考试大类 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
/deep/ .el-form-item__content {
  line-height: inherit;
}

/deep/.el-form-item {
  margin-bottom: 0;
}

/deep/.el-pagination__jump {
  margin-left: 0;
  margin-right: 10px;
}
.queryForm .el-select,.queryForm .el-input{
  width: 150px !important;
}
.check-distri {
  line-height: 32px;
}
.el-tag{
  cursor: pointer;
}
/deep/ .el-dialog__header {
  background: rgb(197 197 197 / 40%) !important;
  padding: 20px;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import {
  logList
} from "@/api/admin/information/informationCollaboration.js"
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      query: {
        keyword: '',
      },
      uploadModule: 'InformationLog',

      tableList: [],
      allDataList: [],

      pageData: {
        pageNum: 1,
        pageSize: 100,
        total: 0
      },

    };
  },
  methods: {
    // 获取数据列表
    getList() {
      logList({...this.query, ...this.pageData}).then(res => {
        if (res.status) {
          this.tableList = res.data
          this.pageData.total = res.total
        }
      })
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val
      this.getList();
    },
  },
  mounted() {
    this.getList()
  }

};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList queryForm">
                <el-input class=" mr-2 " placeholder="查询标题/下载人"  v-model="query.keyword" size="small" maxlength="50" >
                </el-input>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="getList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
              </div>

            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                <tr>

                  <th style="width: 80px;">
                    <input type="checkbox" id="all" name="all"  /> 序号</th>
                  <th>标题</th>
                  <th style="width: 8%;">下载人账号</th>
                  <th style="width: 10%;">下载人</th>
                  <th style="width: 150px;">下载时间</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">

                  <td>

                    {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                  </td>
                  <td>{{obj.wjbt}}</td>
                  <td>{{obj.xzrzh}}</td>
                  <td> {{obj.xzr}}</td>
                  <td> {{obj.xzsj}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                             layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
