import { getRequest,postRequest,putRequest,patchRequest,deleteRequest,downloadRequest} from "@/libs/axios";


// 信息协同列表
export function listPage(param) {
    return getRequest( '/yethan/informationCollaboration/listPage', param);
}

// 信息协同编辑
export function infoerMationEdit(param) {
    return putRequest( '/yethan/informationCollaboration', param);
}

// 通过手机号获取用户信息
export function findUser(param) {
    return getRequest( '/yethan/informationCollaboration/findUser', param);
}

// 通过手机号获取用户信息
export function info(infoId) {
    return getRequest( '/yethan/informationCollaboration/info/'+infoId, {});
}

// 通过手机号获取用户信息
export function removeUser(infoId, memberId) {
    return deleteRequest( '/yethan/informationCollaboration/removeUser/'+infoId+"/"+memberId, {});
}

// 删除上传文件
export function removeFile(infoId, fileId) {
    return deleteRequest( '/yethan/informationCollaboration/removeFile/'+infoId+"/"+fileId, {});
}

// 删除通知
export function delInformation(params) {
    return postRequest( '/yethan/informationCollaboration/delete', params);
}

// 发送成员
export function memberList(params) {
    return getRequest( '/yethan/informationCollaborationMember/list', params);
}

// 下载日志
export function logList(params) {
    return getRequest( '/yethan/informationCollaborationLog/list', params);
}

// 转发
export function forward(params) {
    return putRequest( '/yethan/informationCollaboration/forward', params);
}
//
export function notice(params) {
    return putRequest( '/yethan/informationCollaboration/notice', params);
}